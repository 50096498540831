<template>
<div>

    <Nuevo  v-if="NuevoActivo" :NuevoActivo="NuevoActivo" :Accion="Accion" :Item="Item" @NuevoActivo="NuevoActivo = $event"/>
    <Documentos  v-if="MostrarDocumentosActivo" :MostrarDocumentosActivo="MostrarDocumentosActivo" :Accion="Accion" :Item="Item" @MostrarDocumentosActivo="MostrarDocumentosActivo = $event"/>

  <v-data-table
    :headers="headers"
    :items="arreglo"
    :items-per-page="5"
    :search="busqueda"
    dense
    sort-by="id"
    class="elevation-12 mt-5"
    :loading="TablaVacia"
    loading-text="Cargando... Espere por favor"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >

      <v-toolbar-title>{{titulo}}</v-toolbar-title>

        <v-spacer></v-spacer>
        <!--busqueda-->
        <v-text-field
            v-model="busqueda"
            append-icon="mdi-magnify"
            label="BUSQUEDA"
            single-line
            hide-details
        ></v-text-field>
        <!--busqueda-->
        <v-spacer></v-spacer>

          <v-tooltip bottom> 
          <template v-slot:activator="{ on, attrs }">
            <v-checkbox
              v-bind="attrs"
              v-on="on"
              v-model="checkitems"
              @click="ConsultarUsuariosEps"
              color="primary"
              value="red"
              hide-details
              class="mr-2"
            >
            <template v-slot:label>
              <div>
                Vencidas
                  <v-icon>
                    mdi-magnify
                  </v-icon>
              </div>
              </template>
            </v-checkbox>
          </template>
          <span>Click para mostrar cuentas a suspender</span>
        </v-tooltip>

        <v-btn
          color="success"
          dark
          @click="newItem()"
        >
          NUEVO
        </v-btn>

      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Editar</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          small
          @click="mostrarDocumentos(item)"
        >
          mdi-text-box-search-outline
        </v-icon>
      </template>
      <span>Documentos</span>
    </v-tooltip>
   
    </template>

  </v-data-table>
  </div>
</template>

<script>
  import Nuevo from "./nuevo"
  import Documentos from "./documentos"
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    components: {
        Nuevo,
        Documentos
    },

    props:['titulo','headers','arreglo'],

    created () {
      this.initialize()
    },

    data: () => ({
      checkitems: false,
      busqueda: '',

      Item: {id: 0, 
             apellido1: '', 
             apellido2: '', 
             nombres: '', 
             tipo_documento: '',
             sexo: '',
             numero_documento: '', 
             fecha_nacimiento: '',
             estado_civil: '',
             actividad_economica: '',
             eps: '',
             arl: '',
             caja: 0,
             pension: 0,
             ingreso: 908526,
             direccion: '',
             barrio: '',
             ciudad: '',
             departamento: '',
             celular: '',
             email: '',
             email2: '',
             file: '',
             valor_pagar: 100,
             pin: ''
          },
      Accion: null,

      TablaVacia: null,
      items: [],
      
      NuevoActivo: false,
      MostrarDocumentosActivo: false

    }),

    watch:{
      arreglo(){
          if(!!this.arreglo){
            if(this.arreglo.lenght > 0){
              this.TablaVacia = true
            }else{
              this.TablaVacia = false
            }
          }
        },
    },

    methods: {
      ...mapMutations([]),
      ...mapActions(['ConsultarUsuariosEps']),

      initialize () {
        
      },

      newItem (){
        this.Item= {
          id: 0, 
          apellido1: '', 
          apellido2: '', 
          nombres: '', 
          tipo_documento: '',
          sexo: '',
          numero_documento: '', 
          fecha_nacimiento: '',
          estado_civil: '',
          actividad_economica: '',
          riesgo: 1,
          eps: '',
          arl: '',
          caja: 0,
          pension: 0,
          ingreso: 908526,
          direccion: '',
          barrio: '',
          ciudad: '',
          departamento: '',
          celular: '',
          email: '',
          email2: '',
          file: '',
          valor_pagar: 100,
          pin: ''
        }
        this.NuevoActivo = true
        this.Accion = 'Nuevo'
      },

      editItem (item) {
        this.Item = Object.assign({}, item);
        this.NuevoActivo = true;
        this.MostrarDocumentosActivo = false;
        this.Accion = 'Editar';
      },

      mostrarDocumentos(item){
        this.Item = Object.assign({}, item);
        this.MostrarDocumentosActivo = true;
        this.NuevoActivo = false;
        this.Accion = 'MostrarDocumentos';
      }
    },

    computed: {
      ...mapState([]),
      ...mapGetters(['SINO','SEXO','TipoDocumento', 'EstadoCivil', 'ActividadEconomica', 'EPS', 'ARL', 'CAJA', 'PENSION', 'DatosdeUsuario', 'DEPARTAMENTOS', 'CIUDADES']),
    },
  }
</script>