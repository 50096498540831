var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.NuevoActivo)?_c('Nuevo',{attrs:{"NuevoActivo":_vm.NuevoActivo,"Accion":_vm.Accion,"Item":_vm.Item},on:{"NuevoActivo":function($event){_vm.NuevoActivo = $event}}}):_vm._e(),(_vm.MostrarDocumentosActivo)?_c('Documentos',{attrs:{"MostrarDocumentosActivo":_vm.MostrarDocumentosActivo,"Accion":_vm.Accion,"Item":_vm.Item},on:{"MostrarDocumentosActivo":function($event){_vm.MostrarDocumentosActivo = $event}}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-12 mt-5",attrs:{"headers":_vm.headers,"items":_vm.arreglo,"items-per-page":5,"search":_vm.busqueda,"dense":"","sort-by":"id","loading":_vm.TablaVacia,"loading-text":"Cargando... Espere por favor"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.titulo))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"BUSQUEDA","single-line":"","hide-details":""},model:{value:(_vm.busqueda),callback:function ($$v) {_vm.busqueda=$$v},expression:"busqueda"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-checkbox',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","value":"red","hide-details":""},on:{"click":_vm.ConsultarUsuariosEps},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Vencidas "),_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]},proxy:true}],null,true),model:{value:(_vm.checkitems),callback:function ($$v) {_vm.checkitems=$$v},expression:"checkitems"}},'v-checkbox',attrs,false),on))]}}])},[_c('span',[_vm._v("Click para mostrar cuentas a suspender")])]),_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.newItem()}}},[_vm._v(" NUEVO ")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.mostrarDocumentos(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-text-box-search-outline ")])]}}],null,true)},[_c('span',[_vm._v("Documentos")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }