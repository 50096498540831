<template>
  <div class="eps">
    <tabla titulo="AFILIACIÓN SEGURIDAD SOCIAL" 
          :headers = "headers"
          :arreglo = "UsuariosEpsGetter.items"
            />
            
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import tabla from '@/components/tramites/eps/index-afiliacion';

export default {
  name: 'eps',

   components: {
    tabla
  },
  
  created(){
    this.ConsultarUsuariosEps()
  },

  data: () =>{
    return{
    headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
        },
        { text: 'CLIENTE', value: 'nombre_cliente' },
        { text: 'CEDULA', value: 'numero_documento' },
        { text: 'PAGO', value: 'pago_format' },
        { text: 'FECHA AFILIACIÓN', value: 'fech_pago' },
        { text: 'FECHA VENCE', value: 'fech_vence' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  methods: {
    ...mapActions(['ConsultarUsuariosEps']),
  },

  computed:{
      ...mapGetters(['UsuariosEpsGetter'])
  }
}
</script>
