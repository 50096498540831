<template>
    <div>
      <v-form
          ref="form"
          lazy-validation
      >
        <v-card>
        <v-card-title>
          
        </v-card-title>
        
        <v-card-text>
          <v-container>
            <v-row>
    
               <v-card
                width="1000"
                class="mx-auto"
              >
                <v-toolbar
                  color="light-blue"
                  dark
                >
                  <v-app-bar-nav-icon></v-app-bar-nav-icon>

                  <v-toolbar-title>DOCUMENTOS </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>

                  <v-btn icon>
                    <v-icon>mdi-view-module</v-icon>
                  </v-btn>
                </v-toolbar>

                <h1 v-show="Documentos.items.length == 0" class="red--text ma-">Cliente sin documentos adjuntos</h1>
                
                <v-list
                  v-show="Documentos.items.length > 0"
                  subheader
                  two-line
                  link
                >
                  <v-toolbar-title class="ml-12 mt-5 red--text">{{Item.nombre_cliente}}</v-toolbar-title>
                  <v-subheader  class="ml-9 red--text" inset>Documento: {{Item.numero_documento | filternumeros}}</v-subheader>

                  <v-list-item
                    
                    v-for="file in Documentos.items"
                    :key="file.documento"
                  >
                    <v-list-item-avatar>
                      <v-icon
                        class="light-blue"
                        dark
                        @click="mostrardocumento(file)"
                      >
                        mdi-clipboard-text
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-text="file.documento" @click="mostrardocumento(file)"></v-list-item-title>

                      <v-list-item-subtitle>clic para mostrar el documento</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                       <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon>
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="mostrardocumento(file)"
                            color="red lighten-1"
                          >
                             mdi-redo
                          </v-icon>
                           </v-btn>
                        </template>
                        <span>Ver Documento</span>
                      </v-tooltip>

                    </v-list-item-action>
                  </v-list-item>
                  
                </v-list>

                

                 <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="Ocultar()">Cerrar</v-btn>
                </v-card-actions>

              </v-card>


            </v-row>
          </v-container>
        </v-card-text>

     
      </v-card>
      </v-form>
    </div>
</template>

<script>
require('moment-precise-range-plugin');
import rutas from "@/config.js"
import { mapState, mapGetters, mapActions, mapMutations} from 'vuex'

export default ({
  props:['Accion','Item','MostrarDocumentosActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      },

      Item(){
        this.ConsulDocumentosEps(this.Item)
      }
  },

   created(){
    this.ConsulDocumentosEps(this.Item)
   },

   mounted() {
   },

  data: () => ({
    var: null,
    DialogAceptar: false,
  }),

  methods: {
     ...mapActions(['ConsulDocumentosEps']),

    Ocultar(){
        this.$emit('MostrarDocumentosActivo', false)
    },

    mostrardocumento(file){
      var url = rutas.img+file.documento;
      var win = window.open(url, '_blank');
      win.focus();
    }
  },

  computed:{
    ...mapState(['Alerta', 'Documentos']),
    ...mapGetters(['']),
  },

  filters: {
    filternumeros: function (value) {
      return new Intl.NumberFormat().format(value);
    }
  },

})
</script>