<template>
    <div>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-card>
        <v-card-title>
          
        </v-card-title>
        
        <v-card-text>
          <v-container>
            <v-row>
              
              <v-list three-line>
                <v-list-item
                    ripple
                >
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="5"
                        >
                            <v-img
                            class="mx-auto"
                            max-width="400"
                            src="@/assets/tramites/LogoMinsalud.png"
                            align="center"
                            justify="center"
                            ></v-img>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="7"
                        >
                            <v-list-item-content>
                                <h1 class="text-center">FORMULARIO UNICO DE AFILIACION AL SISTEMA GENERAL DE SEGURIDAD SOCIAL</h1>
                                <h3 class="text-center">Datos básicos de identificación del cotizante o cabeza de familia</h3>
                            </v-list-item-content>
                        </v-col>
                    </v-row>
                </v-list-item>
               </v-list>

              <v-col
                cols="12"
              >
                <v-divider color="black"></v-divider>
              </v-col>
    
             <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="text"
                  v-model="Item.apellido1"
                  label="Primer Apellido *"
                  hint="Ejemplo: Perez"
                  :rules="NameRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="text"
                  v-model="Item.apellido2"
                  label="Segundo Apellido *"
                  hint="Ejemplo: Garcia"
                  :rules="NameRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="text"
                  v-model="Item.nombres"
                  label="Nombres *"
                  hint="Ejemplo: Juan Jose"
                  :rules="NameRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              
               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.tipo_documento"
                  :items="TipoDocumento"
                  item-text="titulo"
                  item-value="id"
                  :menu-props="{ maxHeight: '250'}"
                  label="Tipo Documento Identidad"
                  hint="Indique el tipo de documento de identidad."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="number"
                  v-model="Item.numero_documento"
                  label="Número de documento de identidad *"
                  hint="Indique su número de documento de indentidad"
                  persistent-hint
                  :rules="NameRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.sexo"
                  :items="SEXO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="SEXO?"
                  hint="Indique el sexo de la persona."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="date"
                  v-model="Item.fecha_nacimiento"
                  label="Fecha de nacimiento *"
                  hint="Indique su fecha de nacimiento - la persona debe ser mayor a 18 años"
                  persistent-hint
                  :rules="SelectRules"
                  required
                  @change="diferenciaAnnos()"
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.estado_civil"
                  :items="EstadoCivil"
                  item-text="titulo"
                  item-value="id"
                  :menu-props="{ maxHeight: '250'}"
                  label="Estado Civil"
                  hint="Indique su estado civil."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  @change="BuscarValorPagar()"
                  v-model="Item.actividad_economica"
                  :items="ActividadEconomica"
                  item-text="titulo"
                  item-value="id"
                  :menu-props="{ maxHeight: '300'}"
                  label="Actividad Economica"
                  hint="Indique su actividad economica."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                    disabled
                    v-model="Item.actividad_economica"
                    :items="Riesgo"
                    item-text="id"
                    item-value="id"
                    :menu-props="{ maxHeight: '300'}"
                    label="Tipo de Riesgo"
                    persistent-hint
                    ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  @change="BuscarValorPagar()"
                  v-model="Item.eps"
                  :items="EPS"
                  item-text="titulo"
                  item-value="id"
                  menu-props=auto
                  label="EPS"
                  hint="Seleccione su EPS."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  @change="BuscarValorPagar()"
                  v-model="Item.arl"
                  :items="ARL"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="ARL"
                  hint="Seleccione su ARL."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  @change="BuscarValorPagar()"
                  v-model="Item.caja"
                  :items="CAJA"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="CAJA"
                  hint="Seleccione su Caja."
                  persistent-hint
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  @change="BuscarValorPagar()"
                  v-model="Item.pension"
                  :items="PENSION"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="PENSIÓN"
                  hint="Seleccione su Pensión."
                  persistent-hint
                ></v-select>
              </v-col>

              
                <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="text"
                  v-model="ingresoformat"
                  label="Ingreso Base De Cotización *"
                  hint="Ingrese su Salario Base De Cotización"
                  disabled="disabled"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
               >
                <v-textarea
                  auto-grow
                  counter
                  label="Dirección *"
                  v-model="Item.direccion"
                  hint="Ingrese su dirección de residencia."
                  :rules="NameRules"
                  persistent-hint
                  rows="1"
                  required
                ></v-textarea>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.departamento"
                  :items="DEPARTAMENTOS"
                  item-text="departamento"
                  item-value="id_departamento"
                  menu-props="auto"
                  label="Departamento"
                  hint="Seleccione su Departamento."
                  persistent-hint
                  :rules="SelectRules"
                  @change="BuscarCiudad"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.ciudad"
                  :items="CIUDADES"
                  item-text="municipio"
                  item-value="id_municipio"
                  menu-props="auto"
                  label="Ciudad"
                  hint="Seleccione su Ciudad."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="text"
                  v-model="Item.barrio"
                  label="Barrio *"
                  hint="Ingrese el Barrio de residencia. Ej: El Salitre"
                  :rules="NameRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="number"
                  v-model="Item.celular"
                  label="Celular *"
                  hint="Ingrese su número de celular. Ej: 3005206210"
                  :rules="NameRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="email"
                  v-model="Item.email"
                  label="Email *"
                  hint="Ingrese su correo electrónico. Ej: tucorreo@gmail.com"
                  :rules="EmailRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="Item.id == 0"
              >
                <v-text-field
                  type="email"
                  v-model="Item.email2"
                  label="Ingrese Nuevamente su Email *"
                  hint="Ingrese nuevamente su correo electrónico."
                  :rules="[v => v == Item.email || 'Email No Coincide.']"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
              >
                <v-divider color="black"></v-divider>

                <p class="mt-5">Recuerde que debe tomar <strong>fotocopia ampliada al 150%</strong> de los documentos y luego escanearlos para subirlos al sistema.</p>

                <p>Si usted no cuenta con escaner debe comunicarse al chat de soporte para solicitar la recoleccion fisica de los documentos.</p>

                <h3 class="text-decoration-underline mb-5"><strong>Requisitos Afiliacion</strong></h3>

                <p><strong>Cotizante:</strong> copia cedula de ciudadania.</p>

                <h3 class="text-decoration-underline mb-5"><strong>Beneficiarios Requisitos Afiliación</strong></h3>

                <p><strong>Conyuge:</strong> requiere declaracion juramentada - formato diligenciado por el cotizante o registro civil de matrimonio o declaracion extrajuicion notaria.</p>
                <p><strong>Hijos Edades entre 0 y 6 años:</strong> Registro civil de nacimiento</p>
                <p><strong>Hijos Edades entre 7 y 17 años:</strong>   Registro civil de nacimiento y fotocopia de la tarjeta de identidad.</p>
                <p><strong>Hijos Edades entre 18 a 25 años:</strong>   Registro civil de nacimiento y fotocopia de la cedula de ciudadania.</p>
                <p><strong>Padres:</strong> registro civil del cotizante, fotocopias de cedulas de los padres. Para afiliacion a caja de compensacion debe anexar: declaracion juramentada de dependencia economica.</p>
              </v-col>

                <v-col
                cols="12"
                v-if="Item.id == 0 && Item.tipo_documento == 3"
              >
                <h3 class="red--text" >Es necesario que adjunte como documento adicional de soporte el certificado de migracion colombia. {{Item.numero_documento}}<br> <a href="https://apps.migracioncolombia.gov.co/consultarVEN/" target="_blank">Descarguelo AQUÍ</a></h3>
                <!--
                <v-file-input
                  v-model="Item.file"
                  class="mt-5"
                  accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                  placeholder="Agrega aquí tus documentos"
                  label="Certificado de valides de migracion colombia"
                  :rules="SelectRules"
                  counter
                  multiple
                  show-size
                  prepend-icon="mdi-paperclip"
                  required
                  ref="documentos"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip
                      small
                      label
                      color="primary"
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
                -->
              </v-col>

              <v-col
                cols="12"
                v-if="Item.id == 0"
              >
                <v-file-input
                  v-model="Item.file"
                  accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                  placeholder="Agrega aquí tus documentos"
                  label="Carga De Documentos"
                  :rules="SelectRules"
                  counter
                  multiple
                  show-size
                  prepend-icon="mdi-paperclip"
                  required
                >
                  <template v-slot:selection="{ text }">
                    <v-chip
                      small
                      label
                      color="primary"
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="Item.id == 0"
              >
                <v-text-field
                  disabled
                  type="text"
                  v-model="valorEpsformat"
                  label="Valor a pagar *"
                  hint="Ingrese valor a pagar."
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="Item.id == 0"
              >
                <v-text-field
                  type="text"
                  label="CUPÓN *"
                  hint="Descuento de afiliación."
                  persistent-hint
                  required
                  disabled
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>

          <h3 v-if="valid">* indica campo requerido</h3><br>
          <h3 v-if="!valid" class="red--text">Por favor llene todos los campos requeridos *</h3>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="Ocultar()">Cerrar</v-btn>
          <v-btn
            color="warning"
            @click="reset()"
          >
            Borrar Campos
          </v-btn>

          <v-btn
            v-if="valorEps > 0 && valorEps"
            :disabled="!valid"
            @click="validate"
            color="primary"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    
        <!--Popup Disponible-->
        <v-dialog persistent v-model="DialogAceptar" max-width="650px">
          <v-card>

            <v-toolbar
              color="success"
              dark
            >
            <v-spacer></v-spacer>
              AFILIACIÓN SEGURIDAD SOCIAL
            <v-spacer></v-spacer>
            </v-toolbar>

            <v-card-subtitle class="mt-2">
              <h2 class="text-center">
                CLIENTE<br>
                {{Item.nombre_cliente}}
              </h2>
            </v-card-subtitle>

            <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                
              >
               <v-select
                  disabled
                  v-model="Item.tipo_documento"
                  :items="TipoDocumento"
                  item-text="titulo"
                  item-value="id"
                  :menu-props="{ maxHeight: '250'}"
                  label="Tipo Documento Identidad"
                  hint="Indique el tipo de documento de identidad."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  disabled
                  type="number"
                  v-model="Item.numero_documento"
                  label="Número de documento de identidad *"
                  hint="Indique su número de documento de indentidad"
                  persistent-hint
                  :rules="NameRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.sexo"
                  :items="SEXO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="SEXO?"
                  hint="Indique el sexo de la persona."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  disabled
                  type="date"
                  v-model="Item.fecha_nacimiento"
                  label="Fecha de nacimiento *"
                  hint="Indique su fecha de nacimiento - la persona debe ser mayor a 18 años"
                  persistent-hint
                  :rules="SelectRules"
                  required
                  @change="diferenciaAnnos()"
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.estado_civil"
                  :items="EstadoCivil"
                  item-text="titulo"
                  item-value="id"
                  :menu-props="{ maxHeight: '250'}"
                  label="Estado Civil"
                  hint="Indique su estado civil."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.actividad_economica"
                  :items="ActividadEconomica"
                  item-text="titulo"
                  item-value="id"
                  :menu-props="{ maxHeight: '300'}"
                  label="Actividad Economica"
                  hint="Indique su actividad economica."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.eps"
                  :items="EPS"
                  item-text="titulo"
                  item-value="id"
                  menu-props=auto
                  label="EPS"
                  hint="Seleccione su EPS."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.arl"
                  :items="ARL"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="ARL"
                  hint="Seleccione su ARL."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.caja"
                  :items="CAJA"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="CAJA"
                  hint="Seleccione su Caja."
                  persistent-hint
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.pension"
                  :items="PENSION"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="PENSIÓN"
                  hint="Seleccione su Pensión."
                  persistent-hint
                ></v-select>
                </v-col>

                <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  disabled
                  type="number"
                  v-model="Item.celular"
                  label="Celular *"
                  hint="Ingrese su número de celular. Ej: 3005206210"
                  :rules="NameRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  disabled
                  type="email"
                  v-model="Item.email"
                  label="Email *"
                  hint="Ingrese su correo electrónico. Ej: tucorreo@gmail.com"
                  :rules="EmailRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  disabled
                  type="text"
                  v-model="valorEpsformat"
                  label="Valor a Pagar*"
                  hint="valor a pagar"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col
                cols="8"
              >
                <v-text-field
                  type="password"
                  v-model="Item.pin"
                  label="Ingrese PIN *"
                  hint="Indique el pin de venta."
                  persistent-hint
                  :rules="pinRules"
                  :counter="4"
                  required
                ></v-text-field>
              </v-col>
              
            </v-row></v-container>

            <v-divider></v-divider>

            <v-card-actions class="mt-2">
              <v-spacer></v-spacer>
              <v-btn color="error" @click="DialogAceptar = false">Cerrar</v-btn>
              <v-btn v-if="validate" color="primary" @click="Guardar()">AFILIAR</v-btn>
              <v-btn v-else disabled color="default">AFILIAR</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

 <v-bottom-sheet
      v-model="sheet"
      inset
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          hidden="hidden"
          disabled="disabled"
        >
        </v-btn>
      </template>
      <v-sheet
        class="mx-auto text-center"
        height="200px"
        width="800px"
      >
        <h1 class="red--text">Error</h1>
        <div class="my-3">
          <h3>{{mjesheet}}</h3>
        </div>
        <v-btn class="mt-6" color="error" @click="sheet = !sheet">Cerrar</v-btn>
      </v-sheet>
    </v-bottom-sheet>

    <v-dialog
      v-model="MensajeEps"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 warning mb-5">
          Mensaje Afiliación
        </v-card-title>

        <v-card-text>
          <h3>
          La solicitud de afiliación se ha realizado con exito!<br><br>
          Por favor haga el envío de los documentos soporte al<br>
          whatsapp 3007213000 para terminar el proceso.
          </h3>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="AfiliacionTerminada()"
          >
           cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
require('moment-precise-range-plugin');
import { mapState, mapGetters, mapActions, mapMutations} from 'vuex'

export default ({
  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      },

      Item(){
         this.BuscarCiudad();
      }
  },

   created(){
    this.ConsultarDepartamentos()
   },

   mounted() {
     
     
   },

  data: () => ({
    sheet: false,
    mjesheet: '',
    var: null,
    DialogAceptar: false,
    ItemEnviar: {
        id: 0, 
        apellido1: '', 
        apellido2: '', 
        nombres: '', 
        tipo_documento: '',
        sexo: '',
        numero_documento: '', 
        fecha_nacimiento: '',
        estado_civil: '',
        actividad_economica: '',
        riesgo: 1,
        eps: '',
        arl: '',
        caja: 0,
        pension: 0,
        ingreso: 908526,
        direccion: '',
        barrio: '',
        ciudad: '',
        departamento: '',
        celular: '',
        email: '',
        file: '',
        valor_pagar: 100,
        pin: ''
    },

    Riesgo:[
      {id: 1},
      {id: 2},
      {id: 3},
      {id: 4},
      {id: 5},
    ],

    valid: true,

    ReglasDoc: [
        v => !v || v.size < 2000000 || 'El tamaño del docuento no puede superar las 2 MB!',
      ],

      NameRules: [
        v => !!v || 'Campo Requerido',
        v => (v && v.length >= 3) || 'Campo debe ser mayor a 3 caracteres.',
      ],

      EmailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail Debe ser válido',
      ],

      SelectRules:[
        v => !!v || 'Campo Requerido',
      ],
      pinRules:[
        v => v.length == 4 || 'PIN debe tener 4 digitos',
      ],

   
  }),

  methods: {
     ...mapActions(['SubirArchivos', 'NuevoUsuarioEps', 'ConsultarUsuariosEps', 'ConsultarDepartamentos', 'ConsultarCiudadesxDpto','ConsultarValorPagar']),
     ...mapMutations(['LlenarValorSeguridadSocial','CerrarMensajeEps']),
      validate () {
        this.$refs.form.validate()

        if(this.valid){
          this.DialogAceptar=true;
        }
      },

      reset () {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
        this.LlenarValorSeguridadSocial(0);
      },

      diferenciaAnnos (){
        var date1 = new Date()
        var date2 = new Date(this.Item.fecha_nacimiento);
        
        var diff = Math.floor(date1.getTime() - date2.getTime());
        var day = 1000 * 60 * 60 * 24;

        var days = Math.floor(diff/day);
        var months = Math.floor(days/31);
        var years = Math.floor(months/12)+1;

        if(years < 18){
          this.sheet= true;
          this.mjesheet = "Edad: "+years+' Años .No puede ser menor a 18 años.';
          this.valid = false;
        }else{
          this.valid = true;
        }
      },

    async Guardar (){
        this.ItemEnviar.id = this.Item.id
        this.ItemEnviar.apellido1 = this.Item.apellido1
        this.ItemEnviar.apellido2 = this.Item.apellido2
        this.ItemEnviar.nombres = this.Item.nombres
        this.ItemEnviar.tipo_documento = this.Item.tipo_documento
        this.ItemEnviar.sexo = this.Item.sexo
        this.ItemEnviar.numero_documento = this.Item.numero_documento
        this.ItemEnviar.fecha_nacimiento = this.Item.fecha_nacimiento
        this.ItemEnviar.estado_civil = this.Item.estado_civil
        this.ItemEnviar.actividad_economica = this.Item.actividad_economica
        this.ItemEnviar.riesgo = this.Item.actividad_economica
        this.ItemEnviar.eps = this.Item.eps
        this.ItemEnviar.arl = this.Item.arl
        this.ItemEnviar.caja = this.Item.caja
        this.ItemEnviar.pension = this.Item.pension
        this.ItemEnviar.ingreso = this.Item.ingreso
        this.ItemEnviar.direccion = this.Item.direccion
        this.ItemEnviar.barrio = this.Item.barrio
        this.ItemEnviar.ciudad = this.Item.ciudad
        this.ItemEnviar.departamento = this.Item.departamento
        this.ItemEnviar.celular = this.Item.celular
        this.ItemEnviar.email = this.Item.email 
        this.ItemEnviar.valor_pagar = this.Item.valor_pagar
        this.ItemEnviar.pin = this.Item.pin
      
      await this.NuevoUsuarioEps(this.ItemEnviar)
      if(!this.Alerta.error){
        await this.SubirArchivos(this.Item);
        await this.ConsultarUsuariosEps();
      }
      this.DialogAceptar = false
    },

    Ocultar(){
        this.$emit('NuevoActivo', false)
    },

    BuscarCiudad(){
      this.ConsultarCiudadesxDpto(this.Item);
    },

    BuscarValorPagar(){
      this.ConsultarValorPagar(this.Item);
    },

    AfiliacionTerminada(){
      this.CerrarMensajeEps();
      this.Ocultar();
    }

  },

  computed:{
    ...mapState(['Alerta', 'valorEps', 'MensajeEps']),
    ...mapGetters(['SINO','SEXO','TipoDocumento', 'EstadoCivil', 'ActividadEconomica', 'EPS', 'ARL', 'CAJA', 'PENSION', 'DatosdeUsuario', 'DEPARTAMENTOS', 'CIUDADES']),

    ingresoformat(){
      return '$'+new Intl.NumberFormat().format(this.ItemEnviar.ingreso)+'°°';
    },

    valorEpsformat(){
      return '$'+new Intl.NumberFormat().format(this.valorEps)+'°°';
    }

  }


})
</script>